export default [
  {
    path: '/app/references',
    name: 'References',
    component: () => import('@/views/Admin/References/Index.vue'),
    meta: {
      pageTitle: 'Referanslarımız',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_REFERENCES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/references/add',
    name: 'ReferenceAdd',
    component: () => import('@/views/Admin/References/Add.vue'),
    meta: {
      pageTitle: 'Referanslarımız',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/references',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_REFERENCES_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/references/edit/:id',
    name: 'Referencedit',
    component: () => import('@/views/Admin/References/Edit.vue'),
    meta: {
      pageTitle: 'Referanslarımız',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/references',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_REFERENCES_EDIT',
      action: 'read',
    },
  },
]

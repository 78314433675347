import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/products'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      title: null,
      pcode: null,
      summary: null,
      content: null,
      technical_content: null,
      ordering: null,
      filename: null,
      image: null,
      thumb: null,
      slug: null,
      list_price: null,
      discount_price: null,
      id_currencies: null,
      id_product_categories: null,
      status: null,
      images: [],
      videos: [],
      files: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    videoItem: {
      id: null,
      title: null,
      image: null,
      thumb: null,
      video_embed: null,
      id_products: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    videoItem(state) {
      return state.videoItem
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = {
          id: null,
          title: null,
          pcode: null,
          summary: null,
          content: null,
          technical_content: null,
          ordering: null,
          filename: null,
          image: null,
          thumb: null,
          slug: null,
          list_price: null,
          discount_price: null,
          id_currencies: null,
          id_product_categories: null,
          status: null,
          images: [],
          videos: [],
        }
      }
    },
    RESET_VIDEO_ITEM(state) {
      state.videoItem = {
        id: null,
        title: null,
        image: null,
        thumb: null,
        video_embed: null,
        id_products: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'products.id': id,
        },
        array: false,
        isAdmin: true,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    resetDataItem({ commit }) {
      commit('RESET_DATA_ITEM', true)
    },
    saveImage({ commit }, data) {
      axiosIns
        .post(`${ROOT_URL}/saveImage`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeImageData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteImage`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeFileData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteFile`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    deleteVideo({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteVideo`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    reOrderingImages({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/reOrderingImages`, data)
        .then(response => {
          // commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}

export default [
  {
    path: '/app/translate',
    name: 'Translate',
    component: () => import('@/views/Admin/Translate/Index.vue'),
    meta: {
      pageTitle: 'Dil Çeviri',
      breadcrumb: [
        {
          text: 'Modül Listesi',
          active: true,
        },
      ],
      resource: 'ADMIN_TRANSLATE',
      action: 'read',
    },
  },
  {
    path: '/app/translate/data-list/:language/:id',
    name: 'TranslateDataList',
    component: () => import('@/views/Admin/Translate/DataList.vue'),
    meta: {
      pageTitle: 'Dil Çeviri',
      breadcrumb: [
        {
          text: 'Modül Listesi',
          to: '/app/translate',
          active: false,
        },
        {
          text: 'İçerik Listesi',
          active: true,
        },
      ],
      resource: 'ADMIN_TRANSLATE',
      action: 'read',
    },
  },
]

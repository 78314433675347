export default [
  {
    path: '/app/service-categories',
    name: 'ServiceCategories',
    component: () => import('@/views/Admin/Service_categories/Index.vue'),
    meta: {
      pageTitle: 'Hizmet Kategorileri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_CATEGORIES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/service-categories/add',
    name: 'ServiceCategoryAdd',
    component: () => import('@/views/Admin/Service_categories/Add.vue'),
    meta: {
      pageTitle: 'Hizmet Kategorileri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/service-categories',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_CATEGORIES_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/service-categories/edit/:id',
    name: 'ServiceCategoryEdit',
    component: () => import('@/views/Admin/Service_categories/Edit.vue'),
    meta: {
      pageTitle: 'Hizmet Kategorileri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/service-categories',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_CATEGORIES_EDIT',
      action: 'read',
    },
  },
]

import Vue from 'vue'
import Vuex from 'vuex'

/* Theme Core */
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
/* App Core */
import users from './Admin/Core/users'
import userTypes from './Admin/Core/user_types'
import smtpConfig from './Admin/Core/smtpconfig'
import abilities from './Admin/Core/abilities'
import cropperUploads from './Admin/Core/cropper_uploads'
import fileUpload from './Admin/Core/file_upload'
/* App Data */
import about from './Admin/App/about'
import aboutItems from './Admin/App/about_items'
import blog from './Admin/App/blog'
import blogCategories from './Admin/App/blog_categories'
import carousel from './Admin/App/carousel'
import components from './Admin/App/components'
import contacts from './Admin/App/contacts'
import contents from './Admin/App/contents'
import certificates from './Admin/App/certificates'
import dealers from './Admin/App/dealers'
import fairs from './Admin/App/fairs'
import imageGallery from './Admin/App/image_gallery'
import hrPositions from './Admin/App/hr_positions'
import hrForms from './Admin/App/hr_forms'
import history from './Admin/App/history'
import videoGallery from './Admin/App/video_gallery'
import menus from './Admin/App/menus'
import news from './Admin/App/news'
import overview from './Admin/App/overview'
import productGroups from './Admin/App/product_groups'
import products from './Admin/App/products'
import productColors from './Admin/App/product_colors'
import productOptions from './Admin/App/product_options'
import productPrices from './Admin/App/product_prices'
import popupImages from './Admin/App/popup_images'
import press from './Admin/App/press'
import references from './Admin/App/references'
import services from './Admin/App/services'
import serviceCategories from './Admin/App/service_categories'
import serviceMedia from './Admin/App/service_media'
import socialMedia from './Admin/App/socialmedia'
import siteConfig from './Admin/App/site_config'
import translate from './Admin/App/translate'
import whatsapp from './Admin/App/whatsapp'
/* App Defines */
import currencies from './Admin/Defines/currencies'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    userTypes,
    smtpConfig,
    abilities,
    cropperUploads,
    fileUpload,
    about,
    aboutItems,
    blog,
    blogCategories,
    carousel,
    certificates,
    components,
    contacts,
    contents,
    dealers,
    fairs,
    imageGallery,
    videoGallery,
    hrPositions,
    hrForms,
    history,
    menus,
    news,
    overview,
    productGroups,
    products,
    productColors,
    productOptions,
    productPrices,
    popupImages,
    press,
    references,
    services,
    serviceCategories,
    serviceMedia,
    socialMedia,
    siteConfig,
    translate,
    whatsapp,
    currencies,
  },
  strict: process.env.DEV,
})

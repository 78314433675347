import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/overview'
export default {
  namespaced: true,
  state: {
    dataCounts: 0,
    dataItem: {
      id: null,
      title: null,
      text1: null,
      text2: null,
      custom_text: null,
      text3: null,
      text4: null,
      lead_text: null,
      subtitle: null,
      subtext: null,
      items: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = {
          id: null,
          title: null,
          text1: null,
          text2: null,
          custom_text: null,
          text3: null,
          text4: null,
          lead_text: null,
          subtitle: null,
          subtext: null,
          items: [],
        }
      }
    },
  },
  actions: {
    getDataItem({ commit }) {
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      axiosIns
        .post(`${ROOT_URL}/saveData`, postData)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    deleteItem({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteItem`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    resetDataItem({ commit }) {
      commit('RESET_DATA_ITEM', true)
    },
  },
}

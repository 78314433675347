export default [
  {
    path: '/app/dealer-brands',
    name: 'Dealers',
    component: () => import('@/views/Admin/Dealers/Index.vue'),
    meta: {
      pageTitle: 'Bayiler ve Markalar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/dealer-brands/add',
    name: 'DealersAdd',
    component: () => import('@/views/Admin/Dealers/Add.vue'),
    meta: {
      pageTitle: 'Bayiler ve Markalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/dealer-brands',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/dealer-brands/edit/:id',
    name: 'Dealersedit',
    component: () => import('@/views/Admin/Dealers/Edit.vue'),
    meta: {
      pageTitle: 'Bayiler ve Markalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/dealer-brands',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALERS_EDIT',
      action: 'read',
    },
  },
]

export default [
  {
    path: '/app/certificates',
    name: 'Certificates',
    component: () => import('@/views/Admin/Certificates/Index.vue'),
    meta: {
      pageTitle: 'Sertifikalar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CERTIFICATES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/certificates/add',
    name: 'CertificatesAdd',
    component: () => import('@/views/Admin/Certificates/Add.vue'),
    meta: {
      pageTitle: 'Sertifikalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/certificates',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CERTIFICATES_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/certificates/edit/:id',
    name: 'CertificatesEdit',
    component: () => import('@/views/Admin/Certificates/Edit.vue'),
    meta: {
      pageTitle: 'Sertifikalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/certificates',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CERTIFICATES_EDIT',
      action: 'read',
    },
  },
]

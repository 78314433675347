export default [
  {
    path: '/app/news',
    name: 'News',
    component: () => import('@/views/Admin/News/Index.vue'),
    meta: {
      pageTitle: 'Haberler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_NEWS',
      action: 'read',
    },
  },
  {
    path: '/app/news/add',
    name: 'NewsAdd',
    component: () => import('@/views/Admin/News/Add.vue'),
    meta: {
      pageTitle: 'Haberler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/news',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_NEWS',
      action: 'read',
    },
  },
  {
    path: '/app/news/edit/:id',
    name: 'NewsEdit',
    component: () => import('@/views/Admin/News/Edit.vue'),
    meta: {
      pageTitle: 'Haberler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/news',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_NEWS',
      action: 'read',
    },
  },
]

import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
/* CORE */
import hrPositions from '@/router/routes/Admin/App/Hr_positions'
import hrForms from '@/router/routes/Admin/App/Hr_forms'
import app from './routes/app'
/* ADMIN:CORE */
import configs from './routes/Admin/App/Configs'
import users from './routes/Admin/Core/Users'
import userTypes from './routes/Admin/Core/User_types'
import smtpConfig from './routes/Admin/Core/Configs'
/* ADMIN:DEFINES */

import currencies from './routes/Admin/Defines/Currencies'
/* ADMIN:APP */
import about from './routes/Admin/App/About'
import blog from './routes/Admin/App/Blog'
import blogCategories from './routes/Admin/App/Blog_categories'
import certificates from './routes/Admin/App/Certificates'
import references from './routes/Admin/App/References'
import carousel from './routes/Admin/App/Carousel'
import components from './routes/Admin/App/Components'
import contacts from './routes/Admin/App/Contacts'
import contents from './routes/Admin/App/Contents'
import dealers from './routes/Admin/App/Dealer_brands'
import fairs from './routes/Admin/App/Fairs'
import history from './routes/Admin/App/History'
import news from './routes/Admin/App/News'
import popupImages from './routes/Admin/App/Popup_images'
import press from './routes/Admin/App/Press'
import siteMenu from './routes/Admin/App/Site_menu'
import services from './routes/Admin/App/Services'
import serviceCategories from './routes/Admin/App/Service_categories'
import socialMedia from './routes/Admin/App/Socialmedia'
import translate from './routes/Admin/App/Translate'
import imageGallery from './routes/Admin/App/ImageGallery'
import productGroups from './routes/Admin/App/Product_groups'
import products from './routes/Admin/App/Products'
import videoGallery from './routes/Admin/App/VideoGallery'
import whatsapp from './routes/Admin/App/Whatsapp'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...app,
    ...configs,
    ...users,
    ...userTypes,
    ...siteMenu,
    ...about,
    ...blog,
    ...blogCategories,
    ...references,
    ...carousel,
    ...contacts,
    ...components,
    ...certificates,
    ...contents,
    ...dealers,
    ...fairs,
    ...hrPositions,
    ...hrForms,
    ...history,
    ...news,
    ...popupImages,
    ...press,
    ...smtpConfig,
    ...socialMedia,
    ...translate,
    ...imageGallery,
    ...productGroups,
    ...products,
    ...services,
    ...serviceCategories,
    ...videoGallery,
    ...currencies,
    ...whatsapp,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

export default [
  {
    path: '/app/image-gallery',
    name: 'ImageGallery',
    component: () => import('@/views/Admin/ImageGallery/Index.vue'),
    meta: {
      pageTitle: 'Fotoğraf Galerisi',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PHOTO_GALLERY',
      action: 'read',
    },
  },
  {
    path: '/app/image-gallery/add',
    name: 'ImageGalleryAdd',
    component: () => import('@/views/Admin/ImageGallery/Add.vue'),
    meta: {
      pageTitle: 'Fotoğraf Galerisi',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/image-gallery',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PHOTO_GALLERY',
      action: 'read',
    },
  },
  {
    path: '/app/image-gallery/edit/:id',
    name: 'ImageGalleryEdit',
    component: () => import('@/views/Admin/ImageGallery/Edit.vue'),
    meta: {
      pageTitle: 'Fotoğraf Galerisi',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/image-gallery',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PHOTO_GALLERY',
      action: 'read',
    },
  },
]

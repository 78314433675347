export default [
  {
    path: '/app/video-gallery',
    name: 'VideoGallery',
    component: () => import('@/views/Admin/VideoGallery/Index.vue'),
    meta: {
      pageTitle: 'Video Galerisi',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_VIDEO_GALLERY',
      action: 'read',
    },
  },
  {
    path: '/app/video-gallery/add',
    name: 'VideoGalleryAdd',
    component: () => import('@/views/Admin/VideoGallery/Add.vue'),
    meta: {
      pageTitle: 'Video Galerisi',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/video-gallery',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_VIDEO_GALLERY',
      action: 'read',
    },
  },
  {
    path: '/app/video-gallery/edit/:id',
    name: 'VideoGalleryEdit',
    component: () => import('@/views/Admin/VideoGallery/Edit.vue'),
    meta: {
      pageTitle: 'Video Galerisi',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/video-gallery',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_VIDEO_GALLERY',
      action: 'read',
    },
  },
]

export default [
  {
    path: '/app/about',
    name: 'About',
    component: () => import('@/views/Admin/About/Index.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Hakkımızda',
          active: true,
        },
      ],
      resource: 'ADMIN_ABOUT',
      action: 'read',
    },
  },
]
